// Package imports:
import React from 'react';
// Component imports:
import Button from '../../ui-elements/Button/Button';
import TextImageBlock from '../TextImageBlock/TextImageBlock';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import BulletList from '../../ui-elements/Lists/BulletList';
import CheckList from '../../ui-elements/Lists/CheckList';
import Alert from '../../ui-elements/Alert/Alert';
import PantaVerdmatContactForm from '../ContactForms/PantaVerdmatContactForm/PantaVerdmatContactForm';

const CustomValuationPage: React.FC = () => {
    
    return (
        <div className='main KCL_custom-valuation-page'>
            <div className="main__inner-fluid grid-items">
                <div className='shell'>
                    <HeadSection
                        title={
                            <h1>Sérsniðið verðmat</h1>
                        }
                        summary={
                            <p>Keldan býður upp á verðmats þjónustu fyrir fjárfesta, fyrirtækjaeigendur og sérfræðinga sem þurfa áreiðanlegar upplýsingar og skýrslugerð um raunvirði fyrirtækja.</p>
                        }
                        actions={
                            <Button
                                buttonType="primary"
                                size="lg"
                                anchorProps={{href: '#panta'}}
                            >
                                Panta verðmat
                            </Button>}
                        textAlign='center'
                    />
                </div>
                <div className="section__body">
                    <div className="shell">
                        <TextImageBlock
                            imgSrc="https://cdn.livemarketdata.com/KeldanImages/CustomValuation1.png"
                            imgSide="right"
                        >
                            <h2 id="Vaktarinn_Intensive">Faglegt verðmat á fyrirtækjum</h2>
                            <div className="text-image-block__entry">
                                <p className='paragraph'><b>Verðmats þjónusta Keldunnar</b> er sniðin að þörfum kaupanda og miðar að því að tryggja upplýsta ákvörðun um kaup og sölu fyrirtækja, og að slík viðskipti grundvallist á traustum og gagnreyndum upplýsingum.</p>
                                <p className='paragraph'>Keldan skipar þaulreyndu starfsfólki með aðgengi að víðtækum gagnasöfnum, greiningartólum og nýjustu markaðsgögnum.</p>
                            </div>
                        </TextImageBlock>
                        
                        <TextImageBlock
                            imgSrc="https://cdn.livemarketdata.com/KeldanImages/CustomValuation2.png"
                            imgSide="left"
                        >
                            <h2 id="Vaktarinn_Easy">Gögn fyrir verðmat</h2>
                            <div className="text-image-block__entry">
                                <p className='paragraph'>Eftir að verðmat er pantað gefst kaupanda kostur á að skila inn:</p>
                                <BulletList items={[
                                'Tekjuáætlun fyrirtækisins til 5 ára.',
                                'Kostnaðaráætlun fyrirtækisins til 5 ára.',
                                'Fjárfestingarþörf fyrirtækis til 5 ára.'
                                ]} />
                                <p className='paragraph'>Ef ofangreind gögn eru ekki til staðar gæti það bitnað á nákvæmni verðmatsins og mögulega gefið lakari mynd af verðmætinu.</p>
                            </div>
                        </TextImageBlock>
                    </div>
                </div>
                <div className="section section--contact" id="panta">
                    <div className="shell">
                        <div className="section__inner">
                            <div className="section__head">
                                <h2 id="Vaktarinn_Subscribe">Innifalið í sérsniðnu verðmati</h2>
                                <p className='summary-paragraph'>Keldan skilar frá sér verðmati fyrirtækisins sem óskað var eftir og forsendum þess ásamt eftirfarandi gögnum:</p>
                                <CheckList items={[
                                    'Sjóðstreymisverðmat',
                                    'Markaðssamanburði',
                                    'Næmnigreiningu',
                                    'Markhópalista frá Keldunni fyrir ÍSAT grein fyrirtækisins',
                                    'Gögn á PDF og Excel formi'
                                ]} />
                                <br/>
                                <p><b>Verð: 390.000 kr. án vsk.</b></p>
                                <br/>
                                <Alert
                                    type="info"
                                    headText="Fyrirvari"
                                    text={"Verðmatið sem hér er veitt er útbúið eftir bestu fáanlegum upplýsingum og út frá almennum upplýsingum um markaðsaðstæður og aðrar forsendur sem voru tiltækar við útgáfu þess. Keldan ehf. og tengdir aðilar bera enga ábyrgð á ákvörðunum sem teknar eru á grundvelli verðmatsins, né er ábyrgð tekin á mögulegum skaða eða tjóni sem gæti hlotist af slíkum ákvörðunum. Verðmatið er ekki ráðgjöf eða tilmæli um kaup eða sölu, heldur einungis mat á verðmæti út frá þeim forsendum sem liggja fyrir. Verðmatið endurspeglar ekki endilega framtíðarverðmæti viðkomandi félags og er bundið við forsendur sem kunna að breytast."}
                                />
                            </div>
                            <div className="section__body">
                                <div className="section__body-inner">
                                    <div className="section__content form form--contact">
                                        <PantaVerdmatContactForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomValuationPage;