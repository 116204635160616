// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../../ui-elements/Table/Table';
import Link from '../../../ui-elements/Link/Link';
import Alert from '../../../ui-elements/Alert/Alert';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
// Service imports:
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { IConstructionsReport } from '../../../types/VehicleTypes';

interface IProps {
    id?: string,
    eventId?: string,
    constructionReport: IConstructionsReport,
    isExample?: boolean
}

const ConstructionsOwnedReportBody: React.FC<IProps> = ({
    id,
    eventId,
    constructionReport,
    isExample
}) => {
    const displayReportSection = (type: 'owned' | 'no longer owned') => {
        switch (type) {
            case 'owned':
                if (!constructionReport.constructionReport?.currentOwned) {
                    return <Alert type='info' headText='Engar vinnuvélar í eigu' />
                }
                return (
                    <div className='current-owned-wrapper'>
                        {constructionReport.constructionReport.currentOwned.map((constructionId) => (
                            <div className='owned-item' key={constructionId}>
                                <div className='border-wrapper'>
                                    {constructionId}
                                </div>
                            </div>
                        ))}
                    </div>
                )
            case 'no longer owned':
                if (!constructionReport.constructionReport?.ownedHistory) {
                    return <Alert type='info' headText='Engar vinnuvélar ekki lengur í eigu' />
                }
                const sortedOwnedHistory = [...constructionReport.constructionReport.ownedHistory].sort((a, b) => {
                    if (a.dateRegistered.dateTime.date.year < b.dateRegistered.dateTime.date.year) return 1;
                    if (a.dateRegistered.dateTime.date.year > b.dateRegistered.dateTime.date.year) return -1;
                    if (a.dateRegistered.dateTime.date.month < b.dateRegistered.dateTime.date.month) return 1;
                    if (a.dateRegistered.dateTime.date.month > b.dateRegistered.dateTime.date.month) return -1;
                    if (a.dateRegistered.dateTime.date.day < b.dateRegistered.dateTime.date.day) return 1;
                    if (a.dateRegistered.dateTime.date.day > b.dateRegistered.dateTime.date.day) return -1;
                    return 0;
                })
                return (
                    <Table
                        data={sortedOwnedHistory}
                        tableSize='lg'
                        columns={[{
                            title: 'Skráningarlykill',
                            renderCell: ({ code }) => code,
                            textAlign: 'left'
                        },{
                            title: 'Skráð',
                            renderCell: ({ dateRegistered: { dateTime: { date: { day, month, year} } } }) => (
                                (year <= 1900) ? '-' : `${(day.toString()).padStart(2, '0')}.${(month.toString()).padStart(2, '0')}.${year}`
                            )
                        },{
                            title: 'Eigendaskipti',
                            renderCell: ({ dateTraded: { dateTime: { date: { day, month, year} } } }) => (
                                (year <= 1900) ? '-' : `${(day.toString()).padStart(2, '0')}.${(month.toString()).padStart(2, '0')}.${year}`
                            )
                        }]}
                    />
                )
        }
    }
    const displayReportSectionOld = (type: 'owned' | 'no longer owned') => {
        switch (type) {
            case 'owned':
                if (!constructionReport.constructionReportOld?.currentOwned.anyType) {
                    return <Alert type='info' headText='Engar vinnuvélar í eigu' />
                }
                return (
                    <div className='current-owned-wrapper'>
                        {constructionReport.constructionReportOld.currentOwned.anyType.map(constructionId => (
                            <div className='owned-item' key={constructionId}>
                                <div className='border-wrapper'>
                                    {constructionId}
                                </div>
                            </div>
                        ))}
                    </div>
                )
            case 'no longer owned':
                if (!constructionReport.constructionReportOld?.ownedHistory.anyType) {
                    return <Alert type='info' headText='Engar vinnuvélar ekki lengur í eigu' />
                }
                const sortedOwnedHistory = [...constructionReport.constructionReportOld.ownedHistory.anyType].sort((a, b) => {
                    if (a.dateRegistered.year < b.dateRegistered.year) return 1;
                    if (a.dateRegistered.year > b.dateRegistered.year) return -1;
                    if (a.dateRegistered.month < b.dateRegistered.month) return 1;
                    if (a.dateRegistered.month > b.dateRegistered.month) return -1;
                    if (a.dateRegistered.day < b.dateRegistered.day) return 1;
                    if (a.dateRegistered.day > b.dateRegistered.day) return -1;
                    return 0;
                })
                return (
                    <Table
                        data={sortedOwnedHistory}
                        tableSize='lg'
                        columns={[{
                            title: 'Skráningarlykill',
                            renderCell: ({ code }) => code,
                            textAlign: 'left'
                        },{
                            title: 'Skráð',
                            renderCell: ({ dateRegistered: { day, month, year} }) => (year <= 1900) ? '-' : `${(day.toString()).padStart(2, '0')}.${(month.toString()).padStart(2, '0')}.${year}`,
                        },{
                            title: 'Eigendaskipti',
                            renderCell: ({ dateTraded: { day, month, year} }) => (year <= 1900) ? '-' : `${(day.toString()).padStart(2, '0')}.${(month.toString()).padStart(2, '0')}.${year}`,
                        }]}
                    />
                )
        }
    }
    const displayData = (type: 'owned' | 'no longer owned') => {
        // Construction Report (new)
        if (constructionReport.constructionReport) {
            return displayReportSection(type);
        }
        // Construction Report (old)
        if (constructionReport.constructionReportOld) {
            return displayReportSectionOld(type);
        }
        return <ErrorAlert error={new Error(ErrorMessages.OtherShort)} />
    }

    const getPdfUrl = () => {
        if (constructionReport.constructionReport) {
            return `/Pdf/Vinnuvelar-Eignastada?ssn=${id}&eventId=${eventId}`;
        }
        if (constructionReport.constructionReportOld) {
            return `/Pdf/Vinnuvelar-Eignastada-Gamalt?ssn=${id}&eventId=${eventId}`;
        }
        return '#';
    }

    return (
        <DisplayBox
            title='Vinnuvélar í eigu'
            className='KCL_ViewCompanyAssetsReport'
            asideComponent={
                <div className='report-aside-component'>
                    <div className='link-wrapper'>
                        <Link
                            targetBlank
                            linkSize='15'
                            url={getPdfUrl()}
                            disabled={isExample}
                            icon='pdf'
                        >
                            Sækja skýrslu
                        </Link>
                    </div>
                    <div className='italic-text'>
                        Skýrsla keypt: {constructionReport.eventDate}
                    </div>
                </div>
            }
            grayBackground={isExample}
        >
            <div className='main'>
                {displayData('owned')}
                <h4 className="middle-h4">Vinnuvélar ekki lengur í eigu</h4>
                {displayData('no longer owned')}
            </div>
        </DisplayBox>
    );
}

export default ConstructionsOwnedReportBody;