// Package imports:
import React from 'react';
// Component imports:
import LmdTable from '../../../ui-elements/Table/LmdTable';
import Flag, { FlagNames } from '../../../ui-elements/Flag/Flag';
// Service imports:
import { useApiLmdData } from '../../../services/apiHooks';
import { formatNumber, getTableDate } from '../../../services/utils';
// Type imports:
import { IApiLmdCurrency } from '../../../types/CurrencyTypes';
import { IDefaultProps } from '../../../types/Types';

const CURRENCIES_TO_SHOW = ['AUD','BRL','CAD','CHF','CNY','CZK','DKK','EUR','GBP','HKD','HUF','ILS','INR','ISK','JMD','JPY','KRW','NGN','NOK','NZD','PLN','RUB','SEK','SGD','THB','TRY','TWD','USD','XDR','ZAR'];

type Props = IDefaultProps;

const Tollgengi: React.FC<Props> = ({
    accessToken,
    refreshRateMs
}) => {
    const [ tollgengiCurrencies ] = useApiLmdData<IApiLmdCurrency[]>(
        `/v1/market_data/v1/keldan/base_currency/TOO/cross_currency_list/[${CURRENCIES_TO_SHOW.join(';')}]/snapshot`,
        accessToken,
        refreshRateMs
    );

    const handleData = () => {
        const { data, error } = tollgengiCurrencies;
        if (data === null) return tollgengiCurrencies;

        const sortedData = [...data].sort((a, b) => {
            if (a.Crosscurrency === null && b.Crosscurrency === null) return 0;
            if (a.Crosscurrency === null) return -1;
            if (b.Crosscurrency === null) return 1;
            return a.Crosscurrency > b.Crosscurrency ? 1 : -1;
        });

        return {
            data: sortedData,
            error
        }
    }

    return (
        <div className='KCL_tollgengi'>
            <LmdTable
                apiData={handleData()}
                columns={[{
                    title: 'Gjaldmiðill',
                    renderCell: ({Crosscurrency}) => {
                        return <div className="avatar-and-text">
                            <Flag name={Crosscurrency as FlagNames} />
                            <span>{Crosscurrency}</span>
                        </div>
                    },
                    textAlign: "left",
                    simpleSortable: ({Crosscurrency}) => Crosscurrency
                }, {
                    title: 'Nafn',
                    renderCell: ({Description}) => Description,
                    textAlign: "left",
                    sortable: (a, b) => a.Description?.localeCompare(b.Description ?? '', 'is') ?? -1
                }, {
                    title: 'Gildi',
                    renderCell: ({Averagerate}) => formatNumber(Averagerate),
                    simpleSortable: ({Averagerate}) => Averagerate
                }, {
                    title: 'Dags.',
                    renderCell: ({Datetimerate}) => getTableDate(Datetimerate, 'DD/MM/YY', '.'),
                    simpleSortable: ({Datetimerate}) => (Datetimerate === null) ? null : new Date(Datetimerate.replace(/ /g, "T")).getTime()
                }]}
            />
        </div>
    );
}

export default Tollgengi;