// Package imports:
import React from 'react';
// Component imports:
import Flag, { FlagNames } from '../../../ui-elements/Flag/Flag';
import LmdTable from '../../../ui-elements/Table/LmdTable';
// Service imports:
import { getAPItoday, formatNumber, getTableDate } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiLmdCurrencyRate, IApiLmdCurrency } from '../../../types/CurrencyTypes';

const CURRENCIES_TO_SHOW = ['AED','AUD','BGN','CAD','CHF','CNY','DKK','EUR','GBP','HKD','HUF','ISK','JPY','NOK','PLN','RUB','SEK','THB','TRY','USD','ZAR'];

type Props = IDefaultProps;

interface ICombinedMasterVisa {
    symbol: string | null,
    visa: number | null,
    description: string | null,
    master: number | null,
    dateVisa: string | null,
    dateMaster: string | null
}

const Kreditkort: React.FC<Props> = ({
    accessToken,
    refreshRateMs
}) => {
    const [ creditcardData ] = useApiLmdData<IApiLmdCurrencyRate[]>(
        `/v1/market_data/v1/keldan/base_currency/ISK/cross_currency/[${CURRENCIES_TO_SHOW.join(';')}]/sources/%5BVIS;MAS%5D/rate_types/kortagengi/currency_rate?date_rate=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );
    const [ nameData ] = useApiLmdData<IApiLmdCurrency[]>(
        `/v1/market_data/v1/keldan/base_currency/TOO/cross_currency_list/[${CURRENCIES_TO_SHOW.join(';')}]/snapshot`,
        accessToken,
        refreshRateMs
    );

    const getName = (currencyName: string | null) => {
        const defaultname = '-';
        if (currencyName === null) return defaultname;

        const { data } = nameData;
        if (data === null) return defaultname; 

        for(let entry of data){
            if (entry.Crosscurrency === currencyName){
                return entry.Description;
            }
        }
        return defaultname;
    }

    const handleData = () => {
        const { data, error } = creditcardData;
        const combinedData: ICombinedMasterVisa[] = [];
        if (data === null) return {
            data: combinedData,
            error
        };

        for (let entry of data) {
            const possibleEntry = combinedData.find(e => e.symbol === entry.Crosscurrency && entry.Crosscurrency !== null)
            if (possibleEntry) {
                if (entry.Source === 'MAS') {
                    possibleEntry.master = entry.LastValue;
                    possibleEntry.dateMaster = getTableDate(entry.Daterate, 'DD/MM', '.');
                } else if (entry.Source === 'VIS') {
                    possibleEntry.visa = entry.LastValue;
                    possibleEntry.dateVisa = getTableDate(entry.Daterate, 'DD/MM', '.');
                }
            } else {
                if (entry.Source === 'MAS') {
                    combinedData.push({
                        symbol: entry.Crosscurrency,
                        description: getName(entry.Crosscurrency),
                        master: entry.LastValue,
                        visa: null,
                        dateMaster: getTableDate(entry.Daterate, 'DD/MM', '.'),
                        dateVisa: null
                    })
                } else if (entry.Source === 'VIS') {
                    combinedData.push({
                        symbol: entry.Crosscurrency,
                        description: getName(entry.Crosscurrency),
                        visa: entry.LastValue,
                        master:null,
                        dateMaster: null,
                        dateVisa: getTableDate(entry.Daterate, 'DD/MM', '.')
                    })
                }
            }
        }
        return {
            data: combinedData,
            error
        }
    }

    return (
        <div className='KCL_kreditkort'>
            <LmdTable
                apiData={handleData()}
                columns={[{
                    title: 'Gjaldmiðill',
                    renderCell: ({symbol}) => {
                        return <div className="avatar-and-text">
                            <Flag name={symbol as FlagNames} />
                            <span>{symbol}</span>
                        </div>
                    },
                    textAlign: "left",
                    simpleSortable: ({symbol}) => symbol
                }, {
                    title: 'Nafn',
                    renderCell: ({description}) => description,
                    textAlign: "left",
                    simpleSortable: ({description}) => description
                }, {
                    title: 'VISA',
                    renderCell: ({visa}) => {
                        return formatNumber(visa)
                    },
                    simpleSortable: ({visa}) => visa
                }, {
                    title: 'Dags.',
                    renderCell: ({dateVisa}) => dateVisa,
                    textAlign: "left",
                    simpleSortable: ({dateVisa}) => (dateVisa === null) ? null : new Date(dateVisa).getTime()
                }, {
                    title: 'Mastercard',
                    renderCell: ({master}) => {
                        return formatNumber(master)
                    },
                    simpleSortable: ({master}) => master
                }, {
                    title: 'Dags.',
                    renderCell: ({dateMaster}) => dateMaster,
                    textAlign: 'left',
                    simpleSortable: ({dateMaster}) => (dateMaster === null) ? null : new Date(dateMaster).getTime()
                }]}
            />
        </div>
    );
}

export default Kreditkort;