// Package imports:
import React from 'react';
// Component imports:
import ScrollSpy from '../../../ui-elements/ScrollSpy/ScrollSpy';
import ScrollSpySection from '../../../ui-elements/ScrollSpy/ScrollSpySection';
import BulletList from '../../../ui-elements/Lists/BulletList';
import Link from '../../../ui-elements/Link/Link';

const Personuverndarstefna: React.FC = () => {
    return (
        <div className='personuverndarstefna'>
            <article className='article-overview'>
                <div className='article__head'>
                    <p className='paragraph'>
                        Keldan ehf., kt. 491009-0200, Borgartúni 25, 105 Reykjavík (<span className='paragraph--bold'>“Keldan”</span> eða <span className='paragraph--bold'>“við”</span>) leggur sig fram við
                        að hlíta lögum nr. 90/2018 um persónuvernd og vinnslu persónuupplýsinga. Persónuvernd er okkur
                        mikilvæg og gætum við fyllstu varúðar í meðhöndlun á upplýsingum um viðskiptavini.
                    </p>
                    <p className='paragraph'>
                        Þessi persónuverndarstefna lýsir starfsháttum Keldunnar sem ábyrgðaraðila er varða söfnun, notkun
                        og birtingu persónuupplýsinga einstaklinga sem eiga í viðskiptum við Kelduna, tengiliða sem koma
                        fram fyrir hönd lögaðila í viðskiptum og starfsumsækjanda. Þegar Keldan vinnur persónuupplýsingar
                        fyrir viðskiptavini sína sem vinnsluaðili er það gert á grundvelli sérstaks vinnslusamnings þeirra á milli
                        sem tekur á viðeigandi þáttum persónuverndar hverju sinni.
                    </p>
                    <p className='paragraph'>
                        Fyrirspurnum, ábendingum og athugasemdum sem varða persónuupplýsingar eða persónuverndarstefnu þessa má beina á netfangið <Link url='mailto:info@keldan.is' linkSize='18'>info@keldan.is</Link>.
                    </p>
                </div>
                <div className='article__body'>
                    <ScrollSpy
                        containerClass='article__content'
                        navClass='article__nav'
                        parentClass='personuverndarstefna'
                        initialStep='upplysingar-sem-vid-sofnum'
                    >
                        <ScrollSpySection className='article__section' id='upplysingar-sem-vid-sofnum' label='Upplýsingar sem við söfnum'>
                            <h2>Upplýsingar sem við söfnum</h2>
                            <p className='paragraph'>
                                Þegar þú heimsækir vefsíðu okkar og/eða stofnar til viðskipta gætum við safnað ákveðnum persónuupplýsingum um þig, þar á meðal en ekki takmarkað við:
                            </p>
                            <BulletList
                                items={[
                                    'Nöfn, netföng, símanúmer, kennitölur, heimilisföng, og aðrar tengiliðaupplýsingar.',
                                    'Notkunarupplýsingar eins og IP tölur, tegund vafra, stýrikerfi og heimsóttar vefsíður, safnað sjálfkrafa með vafrakökum og svipaðri tækni.'
                                ]}
                            />
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='notkun-personuupplysinga' label='Notkun persónuupplýsinga'>
                            <h2>Notkun persónuupplýsinga</h2>
                            <p className='paragraph'>
                                Við gætum notað persónuupplýsingarnar sem við söfnum í eftirfarandi tilgangi:
                            </p>
                            <BulletList
                                items={[
                                    'Til að bregðast við fyrirspurn, beiðnum eða ábendingum sem sendar eru í gegnum beiðnakerfið okkar.',
                                    'Til að bæta vefsíðu okkar og þjónustu, þar á meðal að greina stefnur, stauma og óskir notenda.',
                                    'Í kynningar- og/eða markaðsstarfi, svo sem nýjar vörur og tilboð. Viðskiptavinir geta afþakkað markhópapósta með afskráningu í tengli neðst í tölvupóstum.',
                                    'Til að senda út tilkynningu til viðskiptavina ef greiðsluupplýsingar eru að renna út til að tryggja áframhaldandi þjónustu.'
                                ]}
                            />
                            <p className='paragraph'>
                                Keldan skuldbindur sig til að gæta fyllsta trúnaðar um gögn viðskiptavina hvort sem um er að ræða
                                persónugögn eða önnur gögn í eigu viðskiptavina.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='midlun-personuupplysinga' label='Miðlun persónuupplýsinga'>
                            <h2>Miðlun persónuupplýsinga</h2>
                            <p className='paragraph'>
                                Keldan kann að miðla upplýsingum til þriðju aðila sem eru ráðnir af fyrirtækinu til að þjónusta
                                Kelduna í tengslum við fyrirfram ákveðna vinnu, svo sem þjónustuveitendur í tengslum við
                                upplýsingatækni eða hýsingu. Í þeim tilfellum gerir Keldan vinnslusamning við viðkomandi aðila.
                                Slíkur samningur kveður meðal annars á um skyldu vinnsluaðilans til að fylgja fyrirmælum Keldunnar
                                um meðferð persónuupplýsinga og er honum óheimilt að nota þær í öðrum tilgangi. Jafnframt ber
                                vinnsluaðila skylda til að tryggja öryggi upplýsinganna með viðeigandi hætti. Í öðrum tilvikum getur
                                einnig verið nauðsynlegt fyrir Kelduna að miðla persónuupplýsingum til þriðju aðila, til dæmis þegar
                                skylda stendur til þess samkvæmt lögum.
                            </p>
                            <p className='paragraph'>
                                Samkvæmt persónuverndarlögum er Keldunni aðeins heimilt að flytja persónuupplýsingar út fyrir
                                EES ef miðlunin er nauðsynleg, til dæmis til að efna samning og í afmörkuðum tilvikum vegna
                                lögmætra hagsmuna fyrirtækisins. Þá er Keldunni aðeins heimilt að miðla slíkum upplýsingum til
                                landa sem tryggja fullnægjandi vernd (að mati framkvæmdastjórnar ESB) eða ef Keldan hefur gert
                                viðeigandi verndarráðstafanir.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='vafrakokur' label='Vafrakökur'>
                            <h2>Vafrakökur</h2>
                            <p className='paragraph'>
                                Keldan notar vefkökur í þeim tilgangi að bæta virkni vefsíðunnar með það að markmiði að bæta
                                þjónustu við notendur. Það er stefna Keldunnar að nota vefkökur með ábyrgum hætti. Keldan notar
                                vefkökur til að sníða vefsvæðið að þörfum notenda t.d. til að vista stillingar notenda, til að vinna
                                tölfræðilegar upplýsingar, til að greina umferð um vefsvæðið og í markaðslegum tilgangi. Hægt er að
                                loka á vefkökur með því að breyta stillingum í vafra og þannig drega samþykki fyrir notkun á þeim til
                                baka.
                            </p>
                            <p className='paragraph'>
                                Allar persónuupplýsingar sem kunna að verða til við notkun á vefkökum verða meðhöndlaðar og
                                unnið með í samræmi við ákvæði laga nr. 90/2018 um persónuvernd og vinnslu persónuupplýsinga.
                                Keldan lýsir því yfir að ekki verði unnið með slíkar upplýsingar í öðrum tilgangi en að ofan greinir og
                                þá verða upplýsingarnar ekki varðveittar lengur en þörf krefur miðað við tilgang vinnslu.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='tenglar-thridju-adila' label='Tenglar þriðju aðila'>
                            <h2>Tenglar þriðju aðila</h2>
                            <p className='paragraph'>
                                Vefsíða Keldunnar kann að innihalda tengla á vefsíður eða þjónustu þriðja aðila sem eru ekki
                                starfræktar eða stjórnað af okkur. Þessi persónuverndarstefna gildir eingöngu um vefsíðuna okkar og
                                við berum ekki ábyrgð á persónuverndarháttum vefsíðna þriðja aðila. Við hvetjum þig til að skoða
                                persónuverndarstefnur allra vefsíðna þriðja aðila sem þú heimsækir.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='gagnaoryggi' label='Gagnaöryggi'>
                            <h2>Gagnaöryggi</h2>
                            <p className='paragraph'>
                                Keldan leggur mikla áherslu á öryggi við vinnslu og meðferð persónuupplýsinga. Keldan tryggir
                                viðeigandi öryggi, þar með talið með vernd gegn óleyfilegri eða ólögmætri vinnslu og gegn glötun,
                                eyðileggingu eða tjóni fyrir slysni, með viðeigandi tæknilegum og skipulagslegum ráðstöfunum. Komi
                                upp öryggisbrestur við meðferð persónuupplýsinga þar sem staðfest er eða grunur leikur á um að
                                persónuupplýsingar hafi komist í hendur óviðkomandi aðila er Persónuvernd og eftir atvikum,
                                einstaklingum tilkynnt um öryggisbrest nema hann hafi ekki í för með sér mikla áhættu fyrir
                                einstaklinga.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='rettindi-einstaklinga' label='Réttindi einstaklinga'>
                            <h2>Réttindi einstaklinga</h2>
                            <p className='paragraph'>
                                Í persónuverndarlögum er kveðið á um ýmis réttindi einstaklinga, til að mynda aðgangsrétt,
                                flutningsrétt, rétt til leiðréttingar og eyðingar, og rétt til að andmæla eða takmarka vinnslu
                                persónuupplýsinga. Einstaklingum sem ætla að neyta réttinda sinna er nauðsynlegt að sanna á sér
                                deili. Réttindin geta verið takmörkunum háð, sem leiða meðal annars af lögum, hagsmunum annarra
                                sem upplýsingarnar varða eða mikilvægum fjárhags- eða viðskiptahagsmunum. Almennt eru beiðnir
                                sem varða framangreind réttindi einstaklingum að kostnaðarlausu. Keldan áskilur sér rétt til þess að
                                innheimta gjald ef farið er fram á afhendingu fleiri en eins afrits af persónuupplýsingum. Keldan
                                áskilur sér einnig rétt til að neita að verða við beiðni sem er augljóslega tilefnislaus eða óhófleg.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='breytingar-a-personuverndarstefnu' label='Breytingar á persónuverndarstefnu'>
                            <h2>Breytingar á persónuverndarstefnu</h2>
                            <p className='paragraph'>
                                Keldan áskilur sér rétt til að uppfæra eða breyta þessari persónuverndarstefnu hvenær sem er, og
                                allar breytingar munu taka gildi um leið og uppfærð persónuverndarstefnuna hefur verið birt á
                                þessari síðu. Við hvetjum þig til að skoða persónuverndarstefnu Keldunnar reglulega fyrir allar
                                uppfærslur.
                            </p>
                        </ScrollSpySection>
                        <ScrollSpySection className='article__section' id='kvortun-til-personuverndar' label='Kvörtun til Persónuverndar'>
                            <h2>Kvörtun til Persónuverndar</h2>
                            <p className='paragraph'>
                                Ef upp kemur ágreiningur við meðferð persónuupplýsinga á einstaklingur ávallt rétt á því að beina kvörtun
                                til Persónuverndar með tölvupósti á netfangið <Link url='mailto:postur@personuvernd.is' linkSize='18'>postur@personuvernd.is</Link>
                            </p>
                        </ScrollSpySection>
                    </ScrollSpy>
                </div>
            </article>
        </div>
    );
}

export default Personuverndarstefna;